import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Config from "../../Config";

function Sidebar() {

  const [propertyTypes, setPropertyTypes] = useState([]);

  const getPropertyTypes = async (e) => {
    const res = await fetch(`${Config.apiurl}property-types`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;
    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setPropertyTypes(data);
    }
  };

  useEffect(() => {
    getPropertyTypes();
  }, []);

  return (
    <div>
      <div
        id="removeNotificationModal"
        className="modal fade zoomIn"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="NotificationModalbtn-close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mt-2 text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/gsqxdxog.json"
                  trigger="loop"
                  colors="primary:#f7b84b,secondary:#f06548"
                  style={{ width: "100px", height: "100px" }}
                ></lord-icon>
                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                  <h4>Are you sure ?</h4>
                  <p className="text-muted mx-4 mb-0">
                    Are you sure you want to remove this Notification ?
                  </p>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button
                  type="button"
                  className="btn w-sm btn-light"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn w-sm btn-danger"
                  id="delete-notification"
                >
                  Yes, Delete It!
                </button>
              </div>
            </div>
          </div>
          {/* /.modal-content  */}
        </div>
        {/* /.modal-dialog  */}
      </div>
      <div className="app-menu navbar-menu">
        {/* LOGO  */}
        <div className="navbar-brand-box">
          {/* Dark Logo */}
          <Link to="/dashboard" className="logo logo-dark">
            <span className="logo-sm">
              <img src="/images/sm-white.png" alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src="/images/logo-white.png" alt="" height="40" />
            </span>
          </Link>
          {/* Light Logo */}
          <Link to="/dashboard" className="logo logo-light">
            <span className="logo-sm">
              <img src="/images/sm-white.png" alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src="/images/logo-white.png" alt="" height="40" />
            </span>
          </Link>
          <button
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>

        <div id="scrollbar">
          <div className="container-fluid">
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
              <li className="menu-title">
                <span data-key="t-menu">Menu</span>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link menu-link common"
                  to="/dashboard"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                >
                  <i className="las la-tachometer-alt"></i>{" "}
                  <span data-key="t-dashboards">Dashboard</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="lar la-user-circle"></i>{" "}
                  <span data-key="t-maps">DR Super Admin</span>
                </Link>
                <div className="collapse menu-dropdown" id="sidebarSuperAdmin">
                  <ul className="nav nav-sm flex-column">
                    {/* <li className="nav-item">
                      <Link
                        to="/modules"
                        className="nav-link common"
                        data-key="t-google"
                      >
                        Modules
                      </Link>
                    </li> */}

                    <li className="nav-item">
                      <Link
                        to="/languages"
                        className="nav-link common"
                        data-key="t-vector"
                      >
                        Languages
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/booking_engine-languages"
                        className="nav-link common"
                        data-key="t-vector"
                      >
                        Booking Engine Languages
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/role_listing"
                        className="nav-link common"
                        data-key="t-vector"
                      >
                        Roles
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/admin_user_listing"
                        className="nav-link common"
                        data-key="t-vector"
                      >
                        Admin Users
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="ri-settings-5-fill"></i>{" "}
                  <span data-key="t-maps">Settings</span>
                </Link>
                <div className="collapse menu-dropdown" id="sidebarMaps">
                  <ul className="nav nav-sm flex-column">
                    {/* <li className="nav-item">
                      <Link
                        to="/pages"
                        className="nav-link common"
                        data-key="t-google"
                      >
                        Page Settings
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link
                        to="/basic-settings"
                        className="nav-link common"
                        data-key="t-google"
                      >
                        Basic Settings
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="bx bxs-school"></i>{" "}
                  <span data-key="t-maps">Hotel</span>
                </Link>
                <div className="collapse menu-dropdown" id="sidebarHotel">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/hotel-group"
                        className="nav-link common"
                        data-key="t-google"
                      >
                        Chain Hotels
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/individual_hotel"
                        className="nav-link common"
                        data-key="t-vector"
                      >
                        Individual Hotels
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/temporary-property"
                        className="nav-link common"
                        data-key="t-google"
                      >
                        Hotel Creation
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="las la-map"></i>{" "}
                  <span data-key="t-maps">Property Type</span>
                </Link>
                <div className="collapse menu-dropdown" id="sidebarProperty">
                  <ul className="nav nav-sm flex-column">
                  {propertyTypes.map((data, i) => {
                      return (
                    <li className="nav-item" key={i}>
                      <Link
                        to={`/property-types/${data.value}/${data.label}`}
                        className="nav-link common"
                        data-key="t-google"
                      >
                        {data.label}
                      </Link>
                    </li>
                      );
                    })}
                   
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/dr-suite"
                  className="nav-link menu-link common"
                  data-key="t-vector"
                >
                  <i className="las la-cube"></i>{" "}
                  <span data-key="t-maps"> DR Suite</span>
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  className="nav-link menu-link common"
                  to="/loyaltysettings/directrate-loyalty-club"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="las la-gift"></i>{" "}
                  <span data-key="t-maps">Direct Rate Loyalty</span>
                </NavLink>
              </li> */}
            </ul>
          </div>
          {/* <!-- Sidebar --> */}
        </div>

        <div className="sidebar-background"></div>
      </div>
      <div className="vertical-overlay"></div>
    </div>
  );
}

export default Sidebar;
